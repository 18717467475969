import React from "react";
import { bestSellerOne, bestSellerTwo, bestSellerThree, groupI, groupII, groupIV } from "../../assets/images";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <div>
      {" "}
      <main data-aos="fade-up" data-aos-offset="200">
        <section className="container px-10 mb-10 py-8">
          <h1 className="mb-8 border-l-8 py-2 pl-2 text-center text-3xl font-bold">
            Our Latest Courses
          </h1>
          <div className="grid grid-cols-1 gap-5  md:grid-cols-3 lg:grid-cols-3">
            <div
              id="card"
              className="p-4 shadow-[0_10px_20px_rgba(240,_46,_170,_0.7)] bg-[#f5f5f5] transition-all duration-500 hover:shadow-xl dark:bg-slate-950"
            >
              <div className="overflow-hidden">
                <img
                  src={groupI}
                  alt="No image"
                  className="mx-auto md:h-[250px]  md:w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
                />
              </div>
              {/* 
              <div className="flex justify-between pt-2 text-slate-600">
                <p>April 24, 2022</p>
                <p className="line-clamp-1"> By Dilshad</p>
              </div>
              */}
              <div className="space-y-3 py-3">
                <h1 className="md:line-clamp-1 text-xl font-bold">
                  TNPSC GroupI
                </h1>
                <p className=" md:line-clamp-5">
                Tamil Nadu Public Service Commission (TNPSC) every year conducts the TNPSC Group 1 Exam for the various Group 1 posts. TNPSC is expected to announce 32 Group 1 vacancies.
                </p>
                <Link to="/groupI">
                <button
                  className="bg-black text-yellow-50 mt-4 p-2 rounded-md w-[30%] h-10 hover:bg-black hover:text-white hover:font-semibold duration-300 text-base tracking-wide"
                >
                  Read More
                </button>
                </Link>
              </div>
            </div>
            <div
              id="card"
              className="p-4  shadow-[0_10px_20px_rgba(240,_46,_170,_0.7)]  bg-[#f5f5f5] transition-all duration-500 hover:shadow-xl dark:bg-slate-950"
            >
              <div className="overflow-hidden">
                <img
                  src={groupII}
                  alt="No image"
                  className="mx-auto h-[250px] w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
                />
              </div>
              {/* 
              <div className="flex justify-between pt-2 text-slate-600">
                <p>Jan 3, 2023</p>
                <p className="line-clamp-1"> By Akshay</p>
              </div>
              */}
              <div className="space-y-3 py-3">
                <h1 className="md :line-clamp-1 text-xl font-bold">
                  TNPSC GroupII/2A (Non interview process)
                </h1>
                <p className="md:line-clamp-4">
                TNPSC Group 2 posts have a three-stage selection process that includes interviews but the Group 2A posts do not have an interview in their selection process.
                </p>
                <Link to="/groupII/2A">
                <button
                  className="bg-black p-2 text-yellow-50 mt-4 rounded-md w-[30%] h-10 hover:bg-black hover:text-white hover:font-semibold duration-300 text-base tracking-wide"
                >
                  Read More
                </button>
                </Link>
              </div>
            </div>
            <div
              id="card"
              className="p-4  shadow-[0_10px_20px_rgba(240,_46,_170,_0.7)]  bg-[#f5f5f5] transition-all duration-500 hover:shadow-xl dark:bg-slate-950"
            >
              <div className="overflow-hidden">
                <img
                  src={groupIV}
                  alt="No image"
                  className="mx-auto h-[250px] w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
                />
              </div>
              {/* 
              <div className="flex justify-between pt-2 text-slate-600">
                <p>April 24, 2024</p>
                <p className="line-clamp-1"> By Satya</p>
              </div>
              */}
              <div className="space-y-3 py-3">
                <h1 className="md : line-clamp-1 text-xl font-bold">
                 Group IV
                </h1>
                <p className="md:line-clamp-4">
                The TNPSC Group 4 exam is a combined examination that recruits candidates for various services of the Tamil Nadu State, including the Tamil Nadu Ministerial Service and the Tamil Nadu Judicial Ministerial Service. 

                </p>
                <Link to="/forester">
                <button
                  className="bg-black p-2 text-yellow-50 mt-4 rounded-md w-[30%] h-10 hover:bg-black hover:text-white hover:font-semibold duration-300 text-base tracking-wide"
                >
                  Read More
                </button>
                </Link>
              </div>
            </div>
           
          </div>
        </section>
      </main>
    </div>
  );
};

export default Blogs;

