import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-3xl font-bold mb-4">Silver Shop Terms and Conditions:</h2>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Product Information:</h2>
      The silver shop shall provide accurate information about the quality, weight, and purity of the silver products.
        All prices should be clearly displayed, inclusive of taxes.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Ordering and Payment:</h2>
      Customers can place orders through various channels, such as the physical store or online platform.
        Payment options, including accepted forms of payment and any installment plans, should be clearly communicated.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Shipping and Delivery:</h2>
      Delivery times and shipping costs should be transparent.
        The shop is not responsible for delays caused by external factors.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Returns and Refunds:</h2>
      The return policy, including conditions for returns and exchanges, should be clearly stated.
    Refunds will be processed in accordance with the return policy.
        {/* Add your specific terms and conditions content here */}
      </p>
      
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Quality Assurance:</h2>
      The silver shop guarantees the quality and authenticity of its products.
    Customers should report any defects or discrepancies promptly.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Privacy Policy:</h2>
      Customer information will be handled in accordance with privacy laws.
The shop will not share customer information with third parties without consent.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Dispute Resolution:</h2>
      Any disputes will be resolved through negotiation or mediation.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Governing Law:</h2>
      The terms and conditions are governed by the laws of the relevant jurisdiction.
        {/* Add your specific terms and conditions content here */}
      </p>
      <h2 className="text-3xl font-bold mb-4">Gold Chit Fund Terms and Conditions:</h2>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Membership and Contributions:</h2>
      Members must adhere to the terms of the chit fund, including the contribution amount and schedule.
    Contributions can be made through specified channels.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Auctions:</h2>
      The chit fund will conduct regular auctions, and members are expected to participate.
    Winning bids will be subject to specific rules regarding disbursement.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Dividends and Prizes:</h2>
      The chit fund will distribute dividends or prizes as per the agreed-upon terms.
    Members must provide accurate information for disbursement.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Default and Penalties:</h2>
      Members failing to make timely contributions will face penalties as outlined in the terms.
Defaulters may be excluded from participating in auctions until dues are cleared.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Withdrawal and Cancellation:</h2>
      Members can withdraw from the chit fund under specific conditions outlined in the terms.
The process for cancellation and associated fees should be clear.
        {/* Add your specific terms and conditions content here */}
      </p>
      <p className="text-base mb-4">
      <h2 className="text-xl font-bold mb-4"> Dispute Resolution:</h2>
      Disputes among members or with the chit fund management will be resolved through mediation or arbitration.
        {/* Add your specific terms and conditions content here */}
      </p>

      <p className="text-base">
      Remember to customize these terms and conditions based on the specific nature of your silver shop or gold chit fund, and seek legal advice to ensure compliance with local regulations.


        By using our services, you agree to abide by these terms and conditions.
        Please read them carefully.
      </p>
    </div>
  );
};

export default TermsAndConditions;
