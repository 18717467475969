import React from "react";
import { Link } from "react-router-dom";
import { tnpscImg } from "../../assets/images";
const AssistantHo = () => {
  return (
    <div className="md:grid-cols-12  container">
      <h1 className="text-center sm:text-lg  md:text-3xl mt-9 font-bold">
        TNPSC Coaching Centre in Salem
      </h1>
      <ul className="breadcrumb flex items-center text-center justify-center my-4">
        <li className="px-3 text-lg font-semibold">
          <Link to="/">Home</Link> / TNPSC / Assistant Horticulture Officer
        </li>
        
        <li className="px-3 text-lg font-medium">
        
        </li>
      </ul>
      <div className="mt-3">
        <div className="grid md:grid-cols-2 m-3">
          <div className="p-2">
            <img
              src={tnpscImg}
              className="object-cover mx-auto"
              alt="tnpsc-img"
            />
          </div>
          <div className="p-2 tracking-wide">
            <p>
            We are thrilled to announce our exclusive coaching program tailored for the TNPSC Assistant Horticulture Officer exam at Darvin Academy. As the premier TNPSC coaching center in Salem, we take great pride in delivering top-notch training with a team of highly skilled, efficient, and experienced faculty members.   
            </p>
          <br />
            <p>
            The Tamil Nadu Public Service Commission (TNPSC) plays a crucial role in recruiting personnel for various civil services within the state. Our coaching program is meticulously designed to comprehensively cover the syllabus and exam patterns specific to the TNPSC Assistant Horticulture Officer exam. We are dedicated to providing aspirants with the knowledge and skills necessary to excel in this competitive examination.        
            </p>
             <br />
            <p>
               Stay tuned for updates on TNPSC Assistant Horticulture Officer job notifications, as we are committed to keeping our students well-informed and prepared for upcoming opportunities. Join Darvin Academy, and let us assist you in achieving your goal of securing a prestigious position within the esteemed government of Tamil Nadu as an Assistant Horticulture Officer.        
                  </p>
         
      
          </div>
        </div>
      </div>
      {/* Tnpsc GroupI}
      <div className="my-3 lg:ml-32 ml-2 container">
        <h1 className="text-3xl font-bold">Group I</h1>
        {/* Service }
        <div className="mt-2   md:mx-auto">
          {groupIList.map((service, index) => {
            return (
              <div>
                <h3 className="text-xl font-semibold capitalize">{service.title}</h3>
                <ul className="flex-none list-inside">
                  {service.groupIPosting.map((value, key) => {
                    return (
                      <li className="text-left p-2 list-disc">
                        {value.posting}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
         
        </div>
       {/* Exam Pattern }
       <div className="mt-2  md:mx-auto">
          {groupIPattern.map((service, index) => {
            return (
              <div>
                <h3 className="text-xl font-semibold capitalize">{service.title}</h3>
                <ul className="flex-none list-inside">
                  {service.groupIPosting.map((value, key) => {
                    return (
                      <li className="text-left p-2 list-disc">
                        {value.pattern}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
         
        </div>
      </div>/*/}
    </div>
  );
};

export default AssistantHo;
