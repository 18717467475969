// ForgotPasswordForm.js
import React, { useState } from "react";

const ForgotPasswordForm = ({ onCancel }) => {
  const [resetEmail, setResetEmail] = useState("");
  const [resetSuccessMsg, setResetSuccessMsg] = useState("");

  const handleResetEmailChange = (e) => {
    setResetEmail(e.target.value);
  };

  const handleResetSubmit = (e) => {
    e.preventDefault();
    // Implement the password reset logic here
    // For simplicity, let's just log a message to the console in this example.
    console.log("Password reset request submitted for email:", resetEmail);
    setResetSuccessMsg("Password reset email sent successfully");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
    <div className="bg-white p-8 rounded shadow-md sm:w-150 md:w-2/3 lg:w-1/3 xl:w-1/4">
      <form
       
        onSubmit={handleResetSubmit}
      >
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
           Enter Email Address 
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            placeholder="Email Address"
            value={resetEmail}
            onChange={handleResetEmailChange}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold h-10 rounded-md transition duration-300"
            type="submit"
          >
            Reset Password
          </button>
          
          <button
            className="inline-block align-baseline font-bold text-sm text-primeColor hover:text-black mt-4 md:mt-0"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
        
        {resetSuccessMsg && (
          <p className="mt-4 text-green-500">{resetSuccessMsg}</p>
        )}
      </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
