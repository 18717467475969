import React from "react";
import { Link } from "react-router-dom";
import { tnpscImg } from "../../assets/images";
import { groupIIList, groupIIPattern, groupIPosting } from "../../constants";
const GroupII = () => {
  return (
    <div className="md:grid-cols-12  container">
      <h1 className="text-center sm:text-lg  md:text-3xl mt-9 font-bold">
        TNPSC Coaching Centre in Salem
      </h1>
      <ul className="breadcrumb flex items-center text-center justify-center my-4">
        <li className="px-3 text-lg font-semibold">
          <Link to="/">Home</Link>
        </li>
        /
        <li className="px-3 text-lg font-medium">
          TNPSC GroupII/2A
        </li>
      </ul>
      <div className="mt-3">
        <div className="grid md:grid-cols-2 m-3">
          <div className="p-2">
            <img
              src={tnpscImg}
              className="object-cover mx-auto"
              alt="tnpsc-img"
            />
          </div>
          <div className="p-2 tracking-wide">
            <p>
              We Darwin Academy, the best TNPSC Coaching Centre in Salem for all
              Group exams is very glad to announce the aspirants that We Darwin
              Academy is providing exclusive coaching for TNPSC Group Exams with
              highly-skilled, efficient and experienced faculties.
            </p>
            <br />
            <p>
              It’s a well-known fact that We Darwin Academy is the best training
              institute for TNPSC all sort of exams conducted by the TNPSC not
              only in Salem all over the state with proven records and the
              students of We Darwin Academy are directly working under
              government of Tamil Nadu on the respective departments.
            </p>
            <br />
            <p>
              TNPSC is responsible for recruiting personals to the civil
              services in Tamil Nadu.TNPSC deals on all matters relating to
              methods of recruitment to Civil Services and for civil posts and
              also on the principles to be followed in making appointments to
              Civil services and posts and in making promotions and
              transfer.TNPSC job notifications are common and usually rolled out
              by TNPSC every year to recruit the civil servants to work under
              Government of Tamil Nadu directly
            </p>
          </div>
        </div>
      </div>
      {/* Tnpsc GroupI*/}
      <div className="my-3 lg:ml-32 ml-2 container">
        <h1 className="text-3xl font-bold">Group II/2A</h1>
        {/* Service */}
        <div className="mt-2   md:mx-auto">
          {groupIIList.map((service, index) => {
            return (
              <div>
                <h3 className="text-xl font-semibold capitalize">{service.title}</h3>
                <ul className="flex-none list-inside">
                  {service.groupIPosting.map((value, key) => {
                    return (
                      <li className="text-left p-2 list-disc">
                        {value.posting}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
         
        </div>
       {/* Exam Pattern */}
       <div className="mt-2  md:mx-auto">
          {groupIIPattern.map((service, index) => {
            return (
              <div>
                <h3 className="text-xl font-semibold capitalize">{service.title}</h3>
                <ul className="flex-none list-inside">
                  {service.groupIPosting.map((value, key) => {
                    return (
                      <li className="text-left p-2 list-disc">
                        {value.pattern}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
         
        </div>
      </div>
    </div>
  );
};

export default GroupII;
