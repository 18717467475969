import { Link } from "react-router-dom";
import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  hariKaran,
  hariKrishnan,
  gayathiri,
  saranya
} from "../assets/images/index";

  
// =================== NavBarList Start here ====================

export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/home",
  },
  {
    _id: 1002,
    title: "About",
    link: "/about",
  },
  {
    _id: 1003,
    title: "GroupI",
    link: "/Adminsignin",
  },
  {
    _id: 1004,
    title: "GroupII/2A",
    link: "/shop",
  },
  {
    _id: 1005,
    title: "GroupIV",
    link: "/about",
  },
  {
    _id: 1006,
    title: "TNUSRB",
    link: "tnusrb",
  },
  {
    _id: 1007,
    title: "Contact",
    link: "contact",
  },
 
  
];
// =================== NavBarList End here ======================
export const navigationList= [
    {
      "name": "Home",
      "url": "/"
    },
    {
      "name": "About",
      "url": "/about"
    },
    {
      "name": "Group I",
      "url": "/groupI"
    },
    {
      "name": "Group II/2A",
      "url": "/groupII/2a"
    },
    {
      "name": "Group IV",
      "url": "",
      "hasChildren": true,
      "children": [
        {
          "name": "Forester",
          "url": "/forester"
        },
        {
          "name": "Forest Guard",
          "url": "/forestGuard"
        },
        {
          "name": "Forest Watcher",
          "url": "/forestWatcher"
        },
      ]
    },
    {
      "name": "TNUSRB",
      "url": "",
      "hasChildren": true,
      "children": [
        {
          "name": "Sub-Inspector (SI)",
          "url": "/subInspectorTBD"
        },
        {
          "name": "Police",
          "url": "/policeTBD"
        }
      ]
    },
    {
      "name": "TNPSC",
      "url": "",
      "hasChildren": true,
      "children": [
        {
          "name": "Agriculture Officer(AO)",
          "url": "/agriOfficerTBD" 
        },
        {
          "name": "Horticulture Officer(HO)",
          "url": "/hortiOfficerTBD"
        },
        {
          "name": "Assistant Agriculture Officer(AA0)",
          "url": "/assistAgriOfficerTBD"
        },
        {
          "name": "Assitant Horticulture Officer(AHO)",
          "url": "/assistHortiOfficerTBD"
        }
      ]
    },
    {
      "name": "Contact",
      "url": "/contact"
    },
  ];
  export const groupIIList =  [
    {
      title: "service (posting)",
      groupIPosting : [
        {
          posting:"Deputy Commercial Tax Officer (DCTO)",
        },
        {
          posting:"Sub Registrar",
        },
        {
          posting:"Probation Officer in the Prison Department.",
        },
        {
          posting:"Assistant Inspector of Labour",
        },
        {
          posting:"Assistant Inspector in Local Fund Audit Department",
        },{
          posting:"Special Assistant in the Vigilance and AntiCorruption Department.",
        },{
          posting:"Audit Inspector in the Audit Wing of Hindu Religious and Charitable Endowments.",
        },
        {
          posting:"District Employment Officer",
        }
    
      ], 
    },
    {
      title: "application mode",
      groupIPosting: [
        {
          posting: "Online mode – through www.tnpscexams.net website.",
        }
      ]
    },
    {
      title: "mode of exam / counselling",
      groupIPosting: [
        {
          posting: "Offline",
        }
      ]
    },
    {
      title: "qualification",
      groupIPosting: [
        {
          posting: "Any Degree / final year student also apply posting.",
        }
      ]
    },
    {
      title: "selection process",
      groupIPosting: [
        {
          posting: "Preliminary Examination",
        },
        {
          posting: "Main Written Examination.",
        },
        {
          posting: "Interview",
        },
      ]
    },
    {
      title: "language of exam",
      groupIPosting: [
        {
          posting: "english",
        },
        {
          posting: "tamil",
        },
      ],
    },
    {
      title: "age limit",
      groupIPosting: [
        {
          posting: "18-32 years",
        },
      ],
    },
    
    
  ];
  export const groupIList =  [
    {
      title: "service (posting)",
      groupIPosting : [
        {
          posting:"Assistant Commissioner in the Tamil Nadu Hindu Religious and Charitable Endowments Administration Department.",
        },
        {
          posting:"Assistant Commissioner – Commercial Tax officer.",
        },
        {
          posting:"Assistant Director of Rural Development Department.",
        },
        {
          posting:"Deputy Collector.",
        },
        {
          posting:"Deputy Superintendent of Police (Category-I).",
        },{
          posting:"District Registrar.",
        },{
          posting:"Divisional Officer in Fire and Rescue services Department.",
        },
        {
          posting:"District Employment Officer",
        }
    
      ], 
    },
    {
      title: "application mode",
      groupIPosting: [
        {
          posting: "Online mode – through www.tnpscexams.net website.",
        }
      ]
    },
    {
      title: "qualification",
      groupIPosting: [
        {
          posting: "Any Degree / final year student also apply posting.",
        }
      ]
    },
    {
      title: "selection process",
      groupIPosting: [
        {
          posting: "Preliminary Examination",
        },
        {
          posting: "Main Written Examination.",
        },
        {
          posting: "Interview",
        },
      ]
    },
    {
      title: "age limit",
      groupIPosting: [
        {
          posting: "Minimum age 21 years",
        },
        {
          posting: "Maximum age 35 years(BC/MBC/SC/ST) others 30 years",
        },
      ],
    },
    {
      title: "BL Degree",
      groupIPosting: [
        {
          posting: "BC/MBC/SC/ST – 36 Years"
        },
        {
          posting: "Others -31 Years",
        },
      ],
    },
    
  ];
  export const groupIPattern = [
    {
      title: "Preliminary Exam",
      groupIPosting : [
        {
          pattern:"200 Questions(Objective Type – 3 hours) -> 300 Marks",
        },
        {
          pattern:"General Studies – 150",
        },
        {
          pattern:"Aptitude & mental ability – 50",
        },
      ], 
    },
    {
      title: "Main Exam",
      groupIPosting : [
        {
          pattern:"Paper I – > 300 mark (3 hours – descriptive type)",
        },
        {
          pattern:"Paper II – > 300 mark (3 hours – descriptive type)",
        },
        {
          pattern:"Paper III – > 300 mark (3 hours – descriptive type)",
        },
      ], 
    },
    {
      title: "Interview",
      groupIPosting : [
        {
          pattern:"120 mark",
        },
      ], 
    },
  ];
  export const groupIIPattern = [
    {
      title: "Pattern",
      groupIPosting : [
        {
          pattern:"Preliminary Examination : 300 Marks – 1 Hour 30 Mins",
        },
        {
          pattern:"Main Paper I : 300 Marks – 1 Hour 30 Mins",
        },
        {
          pattern:"Main Paper II : 300 Marks – 3 Hours 300",
        },
        {
          pattern: "Interview : 40 Marks",
        },
      ], 
    },
  ];
export const groupIPosting = [
    {
      posting:"Assistant Commissioner in the Tamil Nadu Hindu Religious and Charitable Endowments Administration Department.",
    },
    {
      posting:"Assistant Commissioner – Commercial Tax officer.",
    },
    {
      posting:"Assistant Director of Rural Development Department.",
    },
    {
      posting:"Deputy Collector.",
    },
    {
      posting:"Deputy Superintendent of Police (Category-I).",
    },{
      posting:"District Registrar.",
    },{
      posting:"Divisional Officer in Fire and Rescue services Department.",
    },
    {
      posting:"District Employment Officer",
    }

  ];
export const teamMembers= [
    {
      imageUrl: hariKaran,
      name: " M.HariKrishnan M.Sc(Agri)., ABM., DLL",
      designation: "Author",
      instagram: "https://www.instagram.com/devviresh/",
      linkedin: "https://www.linkedin.com/in/viresh-dev-b2a9951b3/",
      twitter: "https://twitter.com/VireshDev",
      github: "https://github.com/devviresh"
    },
    {
      imageUrl: gayathiri,
      name: "S. Kayathri M.Sc.(Agri)",
      designation: "Founder and Educator",
      instagram: "https://www.instagram.com/wasimr101/",
      linkedin: "https://www.linkedin.com/in/wasim-r-31857014b/",
      twitter: "https://twitter.com/wasim7raja10",
      github: "https://github.com/wasim7raja10"
    },
    {
      imageUrl: hariKaran,
      name: "S.Harikaran BBA, LLB(HONS)",
      designation: "Faculties",
      instagram: "https://www.instagram.com/i_am_saurabhraj/",
      linkedin: "https://www.linkedin.com/in/saurabh-kumar-9566771bb/",
      twitter: "https://twitter.com/i_am_saurabhraj",
      github: "https://github.com/OfficialSaurabh"
    },
    {
      imageUrl: saranya,
      name: "S.M. Saranya MA(ENG), B.Ed",
      designation: " Faculties",
      instagram: "https://www.instagram.com/7091vicky/",
      linkedin: "https://www.linkedin.com/in/vicky-kumar-giri-4864871bb/",
      twitter: "",
      github: "https://github.com/vicky8696"
    },
    {
      imageUrl:hariKaran,
      name: " M. Jayaraj M.Sc.Agri.,Phd.",
      designation: "Faculties",
      instagram: "https://www.instagram.com/7091vicky/",
      linkedin: "https://www.linkedin.com/in/vicky-kumar-giri-4864871bb/",
      twitter: "",
      github: "https://github.com/vicky8696"
    },
  ];
// =================== Special Offer data Start here ============
export const SplOfferData = [
 
  {
    _id: "201",
    img: spfOne,
    productName: "Cap for Boys",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "202",
    img: newArrFour,
    productName: "Tea Table",
    price: "180.00",
    color: "Gray",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "203",
    img: spfThree,
    productName: "Headphones",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "204",
    img: spfFour,
    productName: "Sun glasses",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    img: spfOne,
    productName: "Cap for Boys",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "Tea Table",
    price: "180.00",
    color: "Gray",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "Headphones",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1004,
    img: spfFour,
    productName: "Sun glasses",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1005,
    img: bestSellerOne,
    productName: "Flower Base",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1006,
    img: bestSellerTwo,
    productName: "New Backpack",
    price: "180.00",
    color: "Gray",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1007,
    img: bestSellerThree,
    productName: "Household materials",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1008,
    img: bestSellerFour,
    productName: "Travel Bag",
    price: "220.00",
    color: "Black",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1009,
    img: newArrOne,
    productName: "Round Table Clock",
    price: "44.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1010,
    img: newArrTwo,
    productName: "Smart Watch",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "cloth Basket",
    price: "80.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "Funny toys for babies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "Funny toys for babies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },

  {
    _id: 1014,
    img: newArrTwo,
    productName: "Smart Watch",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "Funny toys for babies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "Smart Watch",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "Travel Bag",
    price: "220.00",
    color: "Black",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "Round Table Clock",
    price: "44.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "Smart Watch",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "cloth Basket",
    price: "80.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "Headphones",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1022,
    img: spfFour,
    productName: "Sun glasses",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "Flower Base",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1024,
    img: spfOne,
    productName: "Cap for Boys",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "Tea Table",
    price: "180.00",
    color: "Gray",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1026,
    img: spfThree,
    productName: "Headphones",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1027,
    img: spfFour,
    productName: "Sun glasses",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1028,
    img: bestSellerOne,
    productName: "Flower Base",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1029,
    img: bestSellerTwo,
    productName: "New Backpack",
    price: "180.00",
    color: "Gray",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1030,
    img: bestSellerThree,
    productName: "Household materials",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1031,
    img: bestSellerFour,
    productName: "Travel Bag",
    price: "220.00",
    color: "Black",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1032,
    img: newArrOne,
    productName: "Round Table Clock",
    price: "44.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1033,
    img: newArrTwo,
    productName: "Smart Watch",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1034,
    img: newArrThree,
    productName: "cloth Basket",
    price: "80.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1035,
    img: newArrFour,
    productName: "Funny toys for babies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1036,
    img: newArrTwo,
    productName: "Funny toys for babies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1037,
    img: newArrFour,
    productName: "Funny toys for babies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1038,
    img: newArrTwo,
    productName: "Smart Watch",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1039,
    img: bestSellerFour,
    productName: "Travel Bag",
    price: "220.00",
    color: "Black",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1040,
    img: newArrOne,
    productName: "Round Table Clock",
    price: "44.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1041,
    img: newArrTwo,
    productName: "Smart Watch",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1042,
    img: newArrThree,
    productName: "cloth Basket",
    price: "80.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1043,
    img: spfThree,
    productName: "Headphones",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1044,
    img: spfFour,
    productName: "Sun glasses",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1045,
    img: bestSellerOne,
    productName: "Flower Base",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1046,
    img: spfOne,
    productName: "Cap for Boys",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];
// =================== PaginationItems End here =================
