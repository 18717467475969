import React from 'react'

const Faq = () => {
  return (
    <div>
      <section className="text-gray-700 md:mt-10 sm:mt-3 mt-2">
        <div className="container mx-auto px-5">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="mb-10 text-center"
          >
            <h1 className="title-font mb-4 text-center text-2xl  text-gray-900 font-semibold sm:text-3xl">
              Frequently Asked Question
            </h1>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="-mx-2 flex flex-wrap sm:mx-auto sm:mb-2 lg:w-4/5"
          >
            <div className="w-full px-4 py-2 lg:w-1/2">
              <details className="mb-4">
                <summary className="rounded-md  bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 py-5 px-5 font-semibold">
                What is TNPSC Eligibility Criteria 2023?
                </summary>

                <p className="pl-4 pt-4 text-left  ">
                The TNPSC eligibility criteria vary according to the post. To apply for a particular position, candidates must check the respective eligibility requirements.
                </p>
              </details>
              <details className="mb-4">
                <summary className="rounded-md bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 py-5 px-4 font-semibold">
                Who releases the TNPSC Eligibility Criteria 2023?
                </summary>

                <p className="pl-4 pt-4 text-left antialiased ">
                The Tamil Nadu Public Service Commission (TNPSC) releases the eligibility criteria. The criteria is released along with the official TNPSC notification.
                </p>
              </details>
            </div>
            <div className="w-full px-4 py-2 lg:w-1/2">
              <details className="mb-4">
                <summary className="rounded-md  bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 py-5 px-5 font-semibold">
                When will TNPSC Group 4 application form release?
                </summary>

                <p className="pl-4 pt-4 text-left antialiased ">
                The TNPSC Group 4 application form will be released soon on the official website. The TNPSC Group 4 exam dates are yet to be announced.
                </p>
              </details>
              <details className="mb-4">
                <summary className="rounded-md  bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 py-5 px-5 font-semibold">
                Can applicants from other states take the TNPSC exam?
                </summary>

                <p className="pl-4 pt-4 text-left antialiased ">
                Candidates are qualified to take the exam if they have opted for Tamil as a subject in high school or college. As an alternative, they will need to pass the TNPSC language test. A language exam can be taken within two years of starting work.
                </p>
              </details>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Faq