import React from "react";
import { Link } from "react-router-dom";
import { tnpscImg } from "../../assets/images";

const ForesterWatcher = () => {
  return (
    <div className="md:grid-cols-12  container">
      <h1 className="text-center sm:text-lg  md:text-3xl mt-9 font-bold">
        TNPSC Coaching Centre in Salem
      </h1>
      <ul className="breadcrumb flex items-center text-center justify-center my-4">
        <li className="px-3 text-lg font-semibold">
          <Link to="/">  Home  </Link>/ TNPSC GroupIV / Forester Watcher
        </li>
        
        <li className="px-3 text-lg font-medium">
         
        </li>
      </ul>
      <div className="mt-3">
        <div className="grid md:grid-cols-2 m-3">
          <div className="p-2">
            <img
              src={tnpscImg}
              className="object-cover mx-auto"
              alt="tnpsc-img"
            />
          </div>
          <div className="p-2 tracking-wide">
            <p>
            At Darvin Academy, we are excited to introduce our specialized coaching program exclusively tailored for TNPSC Group IV, 
            Forester Watcher exams. As the premier TNPSC coaching center in Salem, 
            we take great pride in providing top-notch training through a team of highly skilled, efficient, and experienced faculty members.
             </p>
            <br />
            <p>
            The Tamil Nadu Public Service Commission (TNPSC) plays a pivotal role in recruiting personnel
             for civil services within the state. Our coaching program is meticulously crafted to comprehensively 
             cover the syllabus and exam patterns specific to TNPSC Group IV, Forester Watcher exams. 

            </p>
            <br />
            <p>
            Recognizing the significance of TNPSC's role in recruitment methods,
             civil services, and posts, our coaching program is designed to guide candidates through the entire process. 
             From understanding the principles of recruitment to achieving successful appointments, promotions, and transfers within the Civil services, we provide comprehensive preparation.
            </p>
            <br />
            <p>
            Join Darvin Academy, where excellence is not just a goal but a journey we embark on together. 
            Prepare with us to navigate the challenges of TNPSC Group IV, Forester Watcher exams and 
            pave your way to a successful career in the civil services of Tamil Nadu.
            </p>
          </div>
        </div>
      </div>
      {/* Tnpsc GroupI}
      <div className="my-3 lg:ml-32 ml-2 container">
        <h1 className="text-3xl font-bold">Group I</h1>
        {/* Service }
        <div className="mt-2   md:mx-auto">
          {groupIList.map((service, index) => {
            return (
              <div>
                <h3 className="text-xl font-semibold capitalize">{service.title}</h3>
                <ul className="flex-none list-inside">
                  {service.groupIPosting.map((value, key) => {
                    return (
                      <li className="text-left p-2 list-disc">
                        {value.posting}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
         
        </div>
      {/* {Exam pattern} <div className="mt-2  md:mx-auto">
          {groupIPattern.map((service, index) => {
            return (
              <div>
                <h3 className="text-xl font-semibold capitalize">{service.title}</h3>
                <ul className="flex-none list-inside">
                  {service.groupIPosting.map((value, key) => {
                    return (
                      <li className="text-left p-2 list-disc">
                        {value.pattern}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
         
        </div>
        </div>*/}
    </div>
  );
};

export default ForesterWatcher;
