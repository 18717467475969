import React from "react";
import { Link } from "react-router-dom";
import { BsInstagram, BsLinkedin, BsTwitter, BsGithub } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export default function Team({ leads }) {
  
  return (
    <div className="">
      {/* Leads */}

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className=" my-10 h-56 w-72 rounded-lg md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2  p-5 shadow-xl bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400  "
      >
        <div className=" flex justify-center">
          <div className=" -mt-28 h-32 w-32 rounded-full   border-3 border-red-600 ">
            <img
              src={leads.imageUrl}
              alt={leads.name}
              placeholderSrc="img/Teams/exe.png"
              effect="blur"
              className=" rounded-full  object-cover "
            />
          </div>
        </div>
        <div className=" p-2 ">
          <h1 className="mb-1 text-center text-lg font-bold">{leads.name}</h1>
          <p className="text-center text-sm text-gray-800">
            {leads.designation}
          </p>
        </div>
        <div className="flex justify-center gap-5 pt-5 text-xl text-gray-700   ">
          <Link
            to={leads.instagram}
            className="hover:text-red-600"
            target="_blank"
          >
            <BsInstagram />
          </Link>
          <Link
            to={leads.linkedin}
            className="hover:text-red-600"
            target="_blank"
          >
            <BsLinkedin />
          </Link>
          <Link
            to={leads.twitter}
            className="hover:text-red-600"
            target="_blank"
          >
            <BsTwitter />
          </Link>
          <Link
            to={leads.github}
            className="hover:text-red-600"
            target="_blank"
          >
            {" "}
            <BsGithub />
          </Link>
        </div>
      </div>
    </div>
  );
}
