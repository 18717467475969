// ... (your existing imports)
import React, { useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Inject } from "@syncfusion/ej2-react-grids";
import { toast } from "react-hot-toast";
import { IoCloudDownload } from "react-icons/io5";
import { getDarwinTnpscData } from "../../api/Api";
import { Link } from "react-router-dom";
import "./grid.css";

function AdminDashboard() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const loadData = () => {
    setLoading(true);
    getDarwinTnpscData()
      .then((res) => {
        toast.success(res.data.message);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const actionTemplate = (props) => {
    return (
      <Link
        to={{
          pathname: "/viewOrderList",
        }}
        state={{
          name: props.name,
          email: props.email,
          orderList: props.orderList,
        }}
        exact
      >
        <button
          className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                  hover:text-brightColor transition-all rounded-lg font-bold uppercase "
          type="button"
        >
          {" "}
          <span>
            <IoCloudDownload />
          </span>{" "}
        </button>
      </Link>
    );
  };

  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          pageSettings={{ pageCount: 5 }}
          selectionSettings={{ persistSelection: true }}
          toolbar={["Add", "Edit", "Delete", "Search"]}
          editSettings={{
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            newRowPosition: "Top",
          }}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="name"
              headerText="Name"
              width="150"
              textAlign="Center"
              isPrimaryKey={true}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              width="150"
              textAlign="Center"
            />
            <ColumnDirective
              field="mobile"
              headerText="Mobile No"
              width="150"
              textAlign="Center"
            />
            <ColumnDirective
              field="course"
              headerText=""
              width="150"
              textAlign="Center"
              template={actionTemplate}
            />
            <ColumnDirective
              field="paymentStatus"
              headerText="Payment Status"
              width="150"
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
}

export default AdminDashboard;
