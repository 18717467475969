import React, { useState } from "react";


const OTPVerification = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [enteredOtp, setEnteredOtp] = useState("");
  const [isOtpGenerated, setIsOtpGenerated] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [handleSignIn, setHandleSignIn] = useState(false);

  const handleGenerateOTP = () => {
    // Simulate OTP generation (in a real app, this should be done on the server)
    const generatedOTP = Math.floor(1000 + Math.random() * 9000);
    setOtp(generatedOTP.toString());
    setIsOtpGenerated(true);
  };

  const handleVerifyOTP = () => {
    if (enteredOtp === otp) {
      setIsOtpVerified(true);
      alert("OTP Verified!");
    } else {
      alert("Invalid OTP. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded shadow-md sm:w-96 md:w-2/3 lg:w-1/2 xl:w-1/3">
      <h1 className="text-2xl font-semibold mb-4">Verify OTP</h1>
        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-medium mb-2">
            Mobile Number
          </label>
          <input
            type="text"
            className="w-full h-10 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </div>
      
        <button
          onClick={handleGenerateOTP}
          className="w-full h-10 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
        >
          Generate OTP
  </button>
        {isOtpGenerated && (
          <div className="mt-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Enter OTP
            </label>
            <input
              type="text"
              className="w-full h-10 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter OTP"
              value={enteredOtp}
              onChange={(e) => setEnteredOtp(e.target.value)}
            />
            <button
              onClick={handleVerifyOTP}
              className="mt-2 w-full h-10 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring focus:border-green-300"
            >
              Verify OTP
            </button>
             {/* Back Button */}
             
        <button
          onClick={handleSignIn}
          className="mt-4 w-full h-10 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring focus:border-gray-300"
        >
          Sign In
        </button>
     
          </div>
        )}
        {isOtpVerified && (
          <p className="text-green-500 mt-4">OTP Verified! Access granted.</p>
        )}
      </div>
    </div>
  );
};

export default OTPVerification;
