import * as React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { useState, useEffect } from "react";

import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DataUtil } from "@syncfusion/ej2-data";
import { Browser, extend, isNullOrUndefined } from "@syncfusion/ej2-base";
import "./grid.css";
import { toast, Toast } from "react-hot-toast";
import { getDarwinData } from "../../api/PupilApi";

function AdminDarwin() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const toolbarOptions = ["Search"];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
  };
  const validationRules = { required: true };
  const orderidRules = { required: true, number: true };
  const pageSettings = { pageCount: 5 };

  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    
    setLoading(true);
    getDarwinData()
      .then((res) => {
        //toast.success(res.data.message);
        alert("data"+JSON.stringify(res.data));

        setData(res.data.content);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          //toast.error(error.message);
          //toast.error("error");
        } else {
          //toast.error("Sorry! Something went wrong. Please try again!");
          //toast.error("error");
        }
        setLoading(false);
        console.log(error);
      });
    setLoading(false);
  };
  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowPaging={true}
          editSettings={editSettings}
          pageSettings={pageSettings}
        >
          <ColumnsDirective>
          <ColumnDirective
              field="course"
              headerText="Course"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
          <ColumnDirective
              field="pupilMobile"
              headerText="pupilMobile"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
           
            
            <ColumnDirective
              field="name"
              headerText="Name"
              width="150"
              textAlign="Center"
              isPrimaryKey={true}
            ></ColumnDirective>
            <ColumnDirective
              field="pupilEmail"
              headerText="pupilEmail"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
          
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
}

export default AdminDarwin;
