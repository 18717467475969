import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SilverPrice = () => {
  const [silverPrice, setSilverPrice] = useState(null);

  useEffect(() => {
    const fetchSilverPrice = async () => {
      try {
        const apiKey = 'RAH6GAONSGASTMHX';
        const symbol = 'XAG'; // Silver symbol

        const response = await axios.get(
          `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${symbol}&apikey=${apiKey}`
        );

        const price = response.data['Global Quote']['05. price'];
        setSilverPrice(price);
      } catch (error) {
        console.error('Error fetching silver price:', error);
      }
    };

    fetchSilverPrice();
  }, []);

  return (
    <div>
      <h2>Today's Silver Price</h2>
      {silverPrice !== null ? (
        <p>The current silver price is ${silverPrice} per ounce.</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SilverPrice;
