import React from "react";
import { Link } from "react-router-dom";
import { bestSellerOne, groupI, tnpsc } from "../../assets/images";
const Courses = () => {
  return (
    <div>
         <div className="pb-10 text-center">
        <h1 className="md:text-3xl text-2xl font-semibold text-primeColor mb-6">
          Our Courses
        </h1>
        {/*<p>
          <span className="text-black text-md font-bold">Darwin TNPSC</span> is
          the most popular and the best TNPSC coaching centre in Tamil Nadu to
          provide an exclusive coaching for Tamil Nadu Public Service Commission
          (TNPSC) Group Exams with highly-efficient, skilled and experienced
          faculties.
  </p>*/}
      </div>
    <div className="grid md:grid-cols-3 lg:grid-cols-4">
      <div
        id="card"
        className="p-4 shadow-[0_10px_20px_rgba(240,_46,_170,_0.7)] bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 transition-all duration-500 hover:shadow-xl dark:bg-slate-950"
      >
        <div className="overflow-hidden">
          <img
            src={groupI}
            alt="No image"
            className="mx-auto md:h-[150px]  md:w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
          />
        </div>
        {/* 
    <div className="flex justify-between pt-2 text-slate-600">
      <p>April 24, 2022</p>
      <p className="line-clamp-1"> By Dilshad</p>
    </div>
    */}
        <div className="space-y-3 py-3">
          <h1 className="line-clamp-1 text-xl font-bold">TNPSC GroupI</h1>
          <p className="line-clamp-2">
            Tamil Nadu Public Service Commission (TNPSC) every year conducts the
            TNPSC Group 1 Exam for the various Group 1 posts. TNPSC is expected
            to announce 32 Group 1 vacancies for Deputy Collector, Deputy
            Superintendent of Police, Assistant Commissioner & other posts.
          </p>
          <Link to="/groupI">
            <button className="bg-black text-yellow-50 mt-4 rounded-md w-[30%] h-10 hover:bg-black hover:text-white hover:font-semibold duration-300 text-base tracking-wide">
              Read More
            </button>
          </Link>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Courses;
