import React from "react";
import { Link } from "react-router-dom";

const Brand = () => {
  return (
    <main className="bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400">
      <section data-aos="fade-up" className="container px-10 py-8 md:py-12">
        <div className="grid items-center gap-4 sm:text-ellipsis md:grid-cols-3 md:gap-8">
          <div className=" order-2 flex flex-col items-center gap-4 text-center text-white md:col-span-2  md:items-start md:text-left ">
            <h1 className="text-3xl font-bold text-black ">Darwin Tnpsc</h1>
            <p className="text-black ">
              Candidates should fulfill all the TNPSC Group 1 Eligibility
              Criteria before applying online for the post. All the aspirants
              who possess a Graduation Degree and are at least 21 years or above
              are eligible to apply for TNPSC Group 1 recruitment process.
            </p>
            <Link to="/signup">
              <button className="bg-primeColor rounded text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-black duration-300 font-bold">
                Get Started
              </button>
            </Link>
          </div>
          <div className=" aspect-video ">
            <iframe
              width="300"
              height="300"
              src="https://www.youtube.com/embed/MK21nLIbU2c?si=4s24-G_h3GZAc7Tb"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Brand;
