import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  Route,
  useNavigate,
} from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import { logo, logoLight } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { navBarList, navigationList } from "../../../constants";
import Flex from "../../designLayouts/Flex";
import { useLanguage } from "./LanguageContext";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import {
  ADMIN,
  PUPIL,
  ACCESS_TOKEN,
  ADMIN_TOKEN,
} from "../../../config/Config";
import {
  FaSearch,
  FaUser,
  FaUserEdit,
  FaCaretDown,
  FaShoppingCart,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

const Header = () => {
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setRole = useContextSelector(StateContext, (state) => state.setRole);
  const role = useContextSelector(StateContext, (state) => state.role);
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const navigate = useNavigate();
  const products = useSelector((state) => state.orebiReducer.products);
   // alert("data"+role);
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [category, setCategory] = useState(false);
  const [brand, setBrand] = useState(false);
  const location = useLocation();
  const changeLanguage = useLanguage();
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  useEffect(() => {
    //alert("localStroage role"+role);
    //alert("localStroage.getItem"+JSON.stringify(localStorage.getItem(ACCESS_TOKEN)));
    isAuthenticated();
    let ResponsiveMenu = () => {
      if (window.innerWidth < 780) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };

    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  const isAuthenticated = () => {
    if(localStorage.getItem(ACCESS_TOKEN)) {
      let token = localStorage.getItem(ACCESS_TOKEN);
    //alert("outside"+JSON.stringify(token));
    const base64Url = token.split(".")[1];
    // alert("baseData"+JSON.stringify(base64Url))
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    // alert("base"+JSON.stringify(base64));
    token = JSON.parse(window.atob(base64));
    // alert("token genra"+JSON.stringify(token));
    //console.log(exp, Math.floor(Date.now()/1000));

    if (token.exp <= Math.floor(Date.now() / 1000)) {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(ADMIN_TOKEN);
      // showAlert('Your session has expired !', 'info');
      // this.props.history.push("/");
      //alert("token role from login.js" + token.rol[0]);
      isLoggedIn(false);
    }

    //CHECK IF USER HAS ROLE_ADMIN

    if (token.rol.length >= 1) {
      //alert('token role'+token.rol[0])
      if (token.rol[0] && token.rol[0] === "ADMIN") {
        localStorage.setItem(ADMIN_TOKEN, token.rol[0]);
        setRole(token.rol[0]);
      }
      setRole(token.rol[0]);
      return true;
    } else {
      return false;
    }

    }
    
  };

  const logout = () => {
    //alert("logout is called by loading" + role);
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ADMIN_TOKEN);
    //setRole("desg");
    //alert('inside logout');
    //alert("localstroage remvoe" + role);
    setIsLoggedIn(false);
    setShowUser(false);
    setRole("");
    toast.success("Logout successfully");
    navigate("/");
  };
  return (
    <div>
      <div className="w-full h-18  bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100 sticky top-0 z-50 border-b-[1px] border-b-gray-200">
        <nav className="h-full px-4 max-w-container mx-auto relative">
          <Flex className="flex items-center justify-around h-full">
            <Link to="/">
              <div className="flex justify-items-stretch  items-center">
                <Image className="w-20 object-cover" imgSrc={logo} />
                <h1 className="font pl-md-16 p-1 font-bold text-blue uppercase text-xl">
                  Darwin Tnpsc
                </h1>
              </div>
            </Link>
            <div>
              {/* 
      <button
        className="mr-3 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        onClick={() => changeLanguage('en')}
      >
        English
      </button>
      <button
        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
        onClick={() => changeLanguage('ta')}
      >
        தமிழ்
      </button>
      */}
              {/* Add more buttons for other languages as needed */}
            </div>

            <div className="flex">
              {showMenu && (
                <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="flex items-center w-auto z-50 p-0 gap-2"
                >
                  <>
                    {/*
                  {navBarList.map(({ _id, title, link }) => (
                    <NavLink
                    key={_id}
                    className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                    to={link}
                    state={{ data: location.pathname.split("/")[1] }}
                  >
                    <li>{title}</li>
                  </NavLink>
                  ))}
                   */}
                    <ul
                      id="nav-menu"
                      className="navbar-nav w-full md:w-auto md:space-x-1 lg:flex xl:space-x-2"
                    >
                      {navigationList.map((menu, i) => (
                        <React.Fragment key={`menu-${i}`}>
                          {menu.hasChildren ? (
                            <li className="nav-item nav-dropdown group p-3 relative">
                              <span
                                className={`nav-link uppercase ${
                                  menu.children
                                    .map((c) => c.url)
                                    .includes(Route.asPath) && "active"
                                } inline-flex items-center`}
                              >
                                {menu.name}
                                <svg
                                  className="h-4 w-4 fill-current"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </span>
                              <ul className="nav-dropdown-list p-2 md:w-40 left-1/2 bg-slate-900 hidden transition-all duration-300 group-hover:top-[46px] group-hover:block md:invisible md:absolute md:top-[60px] md:block md:opacity-0 md:group-hover:visible md:group-hover:opacity-100">
                                {menu.children.map((child, i) => (
                                  <li
                                    className="nav-dropdown-item p-2 text-white"
                                    key={`children-${i}`}
                                  >
                                    <Link
                                      to={child.url}
                                      className={`nav-dropdown-link uppercase block ${
                                        Route.asPath === child.url && "active"
                                      }`}
                                    >
                                      {child.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : (
                            <li className="nav-item p-3">
                              <Link
                                to={menu.url}
                                className={`nav-link block uppercase ${
                                  Route.asPath === menu.url && "active"
                                }`}
                              >
                                {menu.name}
                              </Link>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </>
                </motion.ul>
              )}
              {role === ADMIN ? (
                <>
                  <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
                    <div
                      onClick={() => setShowUser(!showUser)}
                      className="flex"
                    >
                      <FaUser />
                      <FaCaretDown />
                    </div>
                    {showUser && (
                      <motion.ul
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="absolute top-6 lg:top-7 mr-left-0 z-50 bg-primeColor w-32 right-2 text-[#767676] h-auto p-4 pb-6"
                      >
                         <Link onClick={() => setShowUser(false)} to="/adminDashboard">
                        
                          <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                            Dashboard
                          </li>
                        </Link>
                        <Link
                          onClick={() => setShowUser(false)}
                          to="/myProfile"
                        >
                          <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                            My Profile
                          </li>
                        </Link>
                        <button onClick={logout}>
                          <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                            Logout
                          </li>
                        </button>
                      </motion.ul>
                    )}
                    {/* 
              <Link to="/cart">
              <div className="relative">
                <FaShoppingCart />
                <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                  {products.length > 0 ? products.length : 0}
                </span>
              </div>
            </Link>
            */}
                  </div>
                </>
              ) : (
                <>
                  {role === PUPIL ? (
                    <>
                      <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
                        <div
                          onClick={() => setShowUser(!showUser)}
                          className="flex"
                        >
                          <FaUser />
                          <FaCaretDown />
                        </div>
                        {showUser && (
                          <motion.ul
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            className="absolute top-6 lg:top-7 mr-left-0 z-50 bg-primeColor w-32 right-2 text-[#767676] h-auto p-4 pb-6"
                          >
                            <Link
                              to="/myProfile"
                              onClick={() => setShowUser(!showUser)}
                            >
                              <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                                My profile
                              </li>
                            </Link>
                            <Link onClick={logout}>
                              <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                                Log out
                              </li>
                            </Link>
                          </motion.ul>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
                        <div
                          onClick={() => setShowUser(!showUser)}
                          className="flex"
                        >
                          <FaUserEdit />
                          <FaCaretDown />
                        </div>
                        {showUser && (
                          <motion.ul
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            className="absolute top-6 lg:top-7 mr-left-0 z-50 bg-primeColor w-32 right-2 text-[#767676] h-auto p-4 pb-6"
                          >
                            <Link
                              to="/signin"
                              onClick={() => setShowUser(!showUser)}
                            >
                              <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                                Login
                              </li>
                            </Link>
                            <Link
                              onClick={() => setShowUser(false)}
                              to="/signup"
                            >
                              <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                                Sign Up
                              </li>
                            </Link>
                            
                          </motion.ul>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}

              <HiMenuAlt2
                onClick={() => setSidenav(!sidenav)}
                className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
              />
              {sidenav && (
                <div className="fixed top-0 left-0 w-full h-screen bg-black text-black bg-opacity-80 z-50">
                  <motion.div
                    initial={{ x: -300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="w-[80%] h-full relative"
                  >
                    <div className="w-full h-full bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100 p-6">
                      <img
                        className="w-28 mb-6"
                        src={logoLight}
                        alt="logoLight"
                      />
                      <ul className="text-black flex flex-col gap-2">
                        {navigationList.map((menu, i) => (
                          <React.Fragment key={`menu-${i}`}>
                            {menu.hasChildren ? (
                              <li className="nav-item nav-dropdown group p-3 relative">
                                <span
                                  className={`nav-link uppercase ${
                                    menu.children
                                      .map((c) => c.url)
                                      .includes(Route.asPath) && "active"
                                  } inline-flex items-center`}
                                >
                                  {menu.name}
                                  <svg
                                    className="h-4 w-4 fill-current"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                  </svg>
                                </span>
                                <ul className="nav-dropdown-list p-2 md:w-40 left-1/2 bg-slate-900 hidden transition-all duration-300 group-hover:top-[46px] group-hover:block md:invisible md:absolute md:top-[60px] md:block md:opacity-0 md:group-hover:visible md:group-hover:opacity-100">
                                  {menu.children.map((child, i) => (
                                    <li
                                      className="nav-dropdown-item p-2 text-white"
                                      key={`children-${i}`}
                                    >
                                      <Link
                                        to={child.url}
                                        className={`nav-dropdown-link uppercase block ${
                                          Route.asPath === child.url && "active"
                                        }`}
                                        onClick={() => setSidenav(false)}
                                      >
                                        {child.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ) : (
                              <li className="nav-item p-3">
                                <Link
                                  to={menu.url}
                                  className={`nav-link block uppercase ${
                                    Route.asPath === menu.url && "active"
                                  }`}
                                  onClick={() => setSidenav(false)}
                                >
                                  {menu.name}
                                </Link>
                              </li>
                            )}
                          </React.Fragment>
                        ))}
                      </ul>
                      {/* 
                    <div className="mt-4">
                      <h1
                        onClick={() => setCategory(!category)}
                        className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                      >
                        Shop by Category{" "}
                        <span className="text-lg">{category ? "-" : "+"}</span>
                      </h1>
                      {category && (
                        <motion.ul
                          initial={{ y: 15, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className="text-sm flex flex-col gap-1"
                        >
                          <li className="headerSedenavLi">New Arrivals</li>
                          <li className="headerSedenavLi">Anklets</li>
                          <li className="headerSedenavLi">Bracelets</li>
                          <li className="headerSedenavLi">Rings</li>
                          <li className="headerSedenavLi">Others</li>
                        </motion.ul>
                      )}
                    </div>
                    <div className="mt-4">
                      <h1
                        onClick={() => setBrand(!brand)}
                        className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                      >
                        Shop by Brand
                        <span className="text-lg">{brand ? "-" : "+"}</span>
                      </h1>
                      {brand && (
                        <motion.ul
                          initial={{ y: 15, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className="text-sm flex flex-col gap-1"
                        >
                          <li className="headerSedenavLi">New Arrivals</li>
                          <li className="headerSedenavLi">Anklets</li>
                          <li className="headerSedenavLi">Bracelets</li>
                          <li className="headerSedenavLi">Rings</li>
                          <li className="headerSedenavLi">Others</li>
                        </motion.ul>
                      )}
                    </div>
                    */}
                    </div>
                    <span
                      onClick={() => setSidenav(false)}
                      className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                    >
                      <MdClose />
                    </span>
                  </motion.div>
                </div>
              )}
            </div>
          </Flex>
        </nav>
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default Header;
