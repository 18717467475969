import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import Adminsignin from "./pages/Account/Adminsignin";
import Terms from "./pages/Account/Terms";
import Forgotpassword from "./pages/Account/Forgotpassword";
import MobileSignIn from "./pages/Account/MobileSignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import SilverPrice from "./components/home/Header/SilverPrice";
import { LanguageProvider } from './components/home/Header/LanguageContext';
import Brand from "./components/Brand/Brand";
import GroupI from "./pages/GroupI/GroupI";
import GroupII from "./pages/GroupII/GroupII";
import Courses from "./pages/Courses/Courses";

import Forester from "./pages/GroupIV/Forester";
import ForesterGuard from "./pages/GroupIV/ForestGuard";
import ForesterWatcher from "./pages/GroupIV/ForestWatcher";
import Horticulture from "./pages/TNPSC/Horticulture";
import AssistantAo from "./pages/TNPSC/AssistantAo";
import AssistantHo from "./pages/TNPSC/AssistantHo";
import AdminTab from "./pages/Account/AdminTab";
import AdminDashboard from "./pages/Account/AdminDashboard";
import './App.css'



const Layout = () => {
  return (
    <div>
      <HeaderBottom />
      <Header />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/journal" element={<Journal />}></Route>
        <Route path="/admindash" element={<AdminDashboard />}></Route>
        <Route path="/SilverPrice" element={<SilverPrice />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<Offer />}></Route>
        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/paymentgateway" element={<Payment />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/signin" element={<SignIn />}></Route>
        <Route path="/groupI" element={<GroupI />}></Route>
        <Route path="/groupII/2A" element={<GroupII/>}></Route>

        <Route path="/forester" element={<Forester/>}></Route>
        <Route path="/forestGuard" element={<ForesterGuard/>}></Route>
        <Route path="/forestWatcher" element={<ForesterWatcher/>}></Route>
        <Route path="/hortiOfficer" element={<Horticulture/>}></Route>
        <Route path="/assistAgriOfficer" element={<AssistantAo/>}></Route>
        <Route path="/assistHortiOfficer" element={<AssistantHo/>}></Route>

        <Route path="/forester" element={<Home/>}></Route>
        <Route path="/forestGuard" element={<Home/>}></Route>
        <Route path="/subInspector" element={<Home/>}></Route>
        <Route path="/forestWatcher" element={<Home/>}></Route>
         <Route path="/agriOfficer" element={<Home/>}></Route>
        <Route path="/hortiOfficer" element={<Home/>}></Route>
        <Route path="/subInspector" element={<Home/>}></Route>
        <Route path="/assistHortiOfficer" element={<Home/>}></Route>
        <Route path="/assistAgriOfficer" element={<Home/>}></Route>
        <Route path="/police" element={<Home/>}></Route>
        
        <Route path="/courses" element={<Courses/>}></Route>
      </Route>
      <Route path="/Adminsignin" element={<Adminsignin />}></Route>
      <Route path="/adminDashboard" element={<AdminTab />}></Route>
      <Route path="/Forgotpassword" element={<Forgotpassword />}></Route>
      <Route path="/Terms" element={<Terms />}></Route>
      <Route path="/MobileSignIn" element={<MobileSignIn />}></Route>
      
      
    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
