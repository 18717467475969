import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { aboutImg, spfTwo } from "../../assets/images";
import { teamMembers } from "../../constants";
import Slider from "react-slick";
import Team from "../Team/Team";
import SampleNextArrow from "../../components/home/NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../../components/home/NewArrivals/SamplePrevArrow";

const About = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  {
    /*const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
*/
  }
  return (
    <div className="max-w-container mx-auto px-4 md:p-6">
      <div className="grid md:grid-cols-2 gap-5 my-5">
        <div className="p-2 items-center justify-center mx-auto my-auto">
          <div className="items-center justify-center">
            <img src={aboutImg} className="object-cover w-full" alt="aboutImg"/>
          </div>
        </div>
        <div className="p-2">
          <div className="items-center justify-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold sm:text-center text-primeColor">About us</h2>
            <br />
            <p className="tracking-wide">
              Lots of job seekers dream of securing a government job. However,
              without the right guide and training, it is not easy to get the
              desired job. We set up Darwin TNPSC Academy with the aim of
              fulfilling the dream of these young aspirants. Our perfect
              training helps you to find the right path to prepare for the
              competitive exam.
            </p>
            <p className="tracking-wide">
              We set the questions of mock tests by focusing on the relevant
              exam pattern. Moreover, we relate the questions to various topics,
              including General Intelligence, General Awareness, Quantitative
              Aptitude, English Language and Computer. Hence, we take the right
              step to help you in beating lots of competitors. Our trainers will
              also provide you with the useful tips and tricks to make your
              preparation easier.
            </p>
          </div>
        </div>
      </div>
      <div className="pb-10 text-center">
        <h1 className="md:text-3xl text-2xl font-semibold text-primeColor mb-6">
          Welcome to Our Team Members
        </h1>
        <p>
          <span className="text-black text-md font-bold">Darwin TNPSC</span> is
          the most popular and the best TNPSC coaching centre in Tamil Nadu to
          provide an exclusive coaching for Tamil Nadu Public Service Commission
          (TNPSC) Group Exams with highly-efficient, skilled and experienced
          faculties.
        </p>
      </div>
     
      <section className="flex flex-wrap justify-center my-4 gap-8">
        {teamMembers.map((leads, index) => (
          <Team leads={leads} key={index} />
        ))}
      </section>
    </div>
  );
};

export default About;
