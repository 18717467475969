import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { logoLight } from "../../assets/images";
import { signinStep1 } from "../../api/Api";
import toast, { Toaster } from "react-hot-toast";
import { ACCESS_TOKEN, ADMIN_TOKEN } from "../../config/Config";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";

const SignIn = () => {
  const navigate = useNavigate();
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole
  );
  const [showLoading, setShowLoading] = useState(false);
  
  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
    const handleEmail = (e) => {
      setEmail(e.target.value);
      setErrEmail("");
  };
  
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };
  // ============= Event Handler End here ===============
  const handleSignUp = (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
    }

    const loginRequest = {
      usernameOrEmail: email,
      //password: phone,
    };
    // ============== Getting the value ==============
    if (email) {
      signinStep1(loginRequest)
      .then((res) => {
        //alert("data"+JSON.stringify(res));
        localStorage.setItem(
          ACCESS_TOKEN,
          res.accessToken ? res.accessToken : null
        );
        setIsLoggedIn(true);
        let token = res.accessToken;
        //alert("outside"+JSON.stringify(token));
        const base64Url = token.split(".")[1];
       // alert("baseData"+JSON.stringify(base64Url))
        const base64 = base64Url.replace("-", "+").replace("_", "/");
       // alert("base"+JSON.stringify(base64));
        token = JSON.parse(window.atob(base64));
       // alert("token genra"+JSON.stringify(token));
        //console.log(exp, Math.floor(Date.now()/1000));
        
        if (token.exp <= Math.floor(Date.now() / 1000)) {
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem(ADMIN_TOKEN);
          // showAlert('Your session has expired !', 'info');
          // this.props.history.push("/");
          //alert("token role from login.js" + token.rol[0]);
          isLoggedIn(false);
        }

          if (token.rol.length >= 1) {
            //alert("token role from login.js" + token.rol[0]);
            setRole(token.rol[0]);
          }
          navigate("/")
        
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or password is incorrect. Please try again !"
          );
         // toast.error("error");
        } else {
          toast.error(error.message);
          //toast.error("error");
        }
        setShowLoading(false);
      });
    }
  };
  return (
    <div className="w-full flex items-center justify-center p-10">
      
      <div className="w-full lgl:w-1/2 ">
        {successMsg ? (
          <div className="w-full flex  justify-center">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signup">
              <button
                className="w-full h-10 bg-primeColor text-gray-200 rounded-md text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
              >
                Sign in Refresh
              </button>
            </Link>
          </div>
        ) : (
          <form className="w-full shadow-[0_10px_20px_rgba(240,_46,_170,_0.7)] bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 p-8 flex items-center justify-center">
            <div className="px-6 py-4 w-full flex flex-col justify-center">
              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                Sign in
              </h1>
              <div className="flex flex-col gap-3">
                {/* Email */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Work Email
                  </p>
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="email"
                    placeholder="john@workemail.com"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleSignUp}
                  className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
                >
                  Sign In
                </button>
                <p className="text-sm text-center font-titleFont font-medium">
                  Don't have an Account?{" "}
                  <Link to="/signup">
                    <span className="hover:text-blue-600 duration-300">
                      Sign up
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        )}
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
        />
    </div>
  );
};

export default SignIn;
