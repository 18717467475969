import React, { useState } from "react";
import { FaMobile } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMsg, setSuccessMsg] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: "", // Clear previous errors on change
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    const validationErrors = {};
    if (!formData.fullName.trim()) {
      validationErrors.fullName = "Enter your full name";
    }

    if (!formData.email.trim()) {
      validationErrors.email = "Enter your email";
    }

    if (!formData.message.trim()) {
      validationErrors.message = "Enter your message";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // If validation passes, show success message
    setSuccessMsg("Your message has been sent!");

    // Clear form data
    setFormData({
      fullName: "",
      email: "",
      message: "",
    });
  };

  return (
    <div className="max-w-container mx-auto px-4 flex flex-col items-center">
    <h1 className="text-center sm:text-lg  md:text-3xl mt-9 font-bold">
        TNPSC coaching center contact data
      </h1>
      <ul className="breadcrumb flex items-center text-center justify-center my-4">
        <li className="px-3 text-lg font-semibold">
          <Link to="/">Home</Link>
        </li>
        /
        <li className="px-3 text-lg font-medium">
          Contact us
        </li>
      </ul>
    <div className="pb-10 mb-4 p-3 text-center rounded-sm bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400">
      <h1 className="max-w-[600px] text-left text-lightText font-semibold mb-4">
      <MdLocationOn className="mr-2" />
        <span className="text-primeColor">Address:</span>
      </h1>
      <div className="mb-4 text-left">
        <p className="flex items-center mb-2">

          33/A7, A9<br />Sree Devi Complex,<br />Omaluran Street,<br />Vijaya Mahal Near,<br />Mecheri,<br />Salem Dt<br />Tamilnadu, India - 636453
        </p>
        <p className="flex items-center mb-2">
          <FaMobile className="mr-2" />
          Mobile: +91 7010400823
        </p>
        
      </div>

      <Link to="/signup">
        <button className="w-52 h-10 bg-black rounded text-white hover:bg-black duration-300">
          GET STARTED
        </button>
      </Link>
    </div>
  </div>
  );
};

export default ContactUsForm;
